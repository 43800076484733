const ptype = [
    {
      'id': '0',
      'type': '',
      'description': 'ALL',
    },
    {
      'id': '1',
      'type': 'react',
      'description': 'REACT',
    },
    {
      'id': '2',
      'type': 'python',
      'description': 'PYTHON',
    },
    {
      'id': '3',
      'type': 'datascience',
      'description': 'DATA SCIENCE',
    },      
  ];

export default ptype;  