import React from 'react';
import ReactDOM from 'react-dom';

import App from '../src/routes/App';

ReactDOM.render
(
    <App />,
    document.getElementById('app')

);