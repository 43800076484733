import React, {useState,useEffect} from 'react'
import ProjectsInfo from '../data/projects.js';
import TypesInfo from '../data/ptypes.js'
import ProjectItem from './ProjectItem'
import Button from './Button.jsx';
import '../style/componentstyle/portfolio.css'


const Portfolio = () => {

    const [searchText, setSearchText] = useState('')
    const [projectsData, setProjectsData] = useState(ProjectsInfo)
    const [activeButton, setActiveButton] = useState('0')

    useEffect(() => {
        if (searchText === '') return;
        setProjectsData(() => 
            ProjectsInfo.filter((item) => 
                item.desc.toLowerCase().match(searchText.toLowerCase())
            )
        )
    }, [searchText]);

    const handleFilter = (e) => {
        e.preventDefault();
        setSearchText(e.target.value);
        e.target.value === '' && setProjectsData(ProjectsInfo);
        // setProjectsData(ProjectsInfo);
        setActiveButton(e.target.id);
    };

    return (
        <section id="portfolio">
            <div className="tittle">
            PROJECTS
            </div>
            <div className="header-bar"></div>

            <div className="filter-wrap">
                <div className="row">
                    {TypesInfo.map(type => (
                       <Button key={type.id} className={type.id === activeButton ? "active-button" : "button"} ptype={type} onPress={handleFilter} ></Button> 
                    ))}
                </div>
            </div>
            <div className="gallery-container">
                <div id="gallery" className="container wrap">
                    {projectsData.map((item) => (
                        <ProjectItem
                            key={item.id}
                            title={item.name}
                            desc={item.desc}
                            img={item.img}
                        />
                    ))
                    }
                </div>                

            </div>

        </section>    
    )}

export default Portfolio