import React from 'react'
import {Link} from 'react-router-dom'

// import '../fonts/mdi/css/materialdesignicons.css'
// import '../fonts/mdi/css/materialdesignicons.min.css'
import '../style/componentstyle/welcome.css'


const Welcome = () => (
    <section id="welcome" className="flex">
        <div id="pt" className="canvas"></div>
        <div className="flex">
            <div className="text">
                Hello, I'm <span className="highlight">Avilio Amador</span>.
                <br />
                and I'm a<span> Software Engineer</span>.
            </div>

            <Link to="/about">
                <div className="button page-link" dest="about">
                    <a className="view">Know more about me</a> 
                    <i className="mdi mdi-arrow-right"></i>
                </div>
            </Link>
        </div> 
    </section>
 
)

export default Welcome