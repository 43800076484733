
import MacaoImg from '../img/macao_store.jpg';
import HarryImg from '../img/harry_app.jpg';
import ComingSoon from '../img/coming_soon.png';


const projects = [
  {
    id: 1,
    name: 'Macao Garden Store',
    desc:
      'React | Hooks | Redux',
    img: MacaoImg,
  },
  {
    id: 2,
    name: 'Harry Potter App',
    desc:
      'React | Hooks | API',
    img: HarryImg,
  },
  {
    id: 3,
    name: 'Future Project',
    desc:
      'Python | MongoDB',
    img: ComingSoon,
  },
  {
    id: 4,
    name: "Future Project",
    desc:
      'Angular | Typescript',
    img: ComingSoon,
  },
  {
    id: 5,
    name: 'Future Project',
    desc:
      'DataScience | Jupiter Notebook',
    img: ComingSoon,
  },
  {
    id: 6,
    name: 'Future Project',
    desc:
      'Python | MongoDB',
    img: ComingSoon,
  },
  {
    id: 7,
    name: 'Future Project',
    desc:
      'DataScience | Jupiter Notebook',
    img: ComingSoon,
  },
  {
    id: 8,
    name: 'Future Project',
    desc:
      'Angular / Ruby on Rails',
    img: ComingSoon,
  },
  {
    id: 9,
    name: 'Future Project',
    desc:
      'React.js / Node',
    img: ComingSoon,
  },        
];

export default projects;
