import React from 'react'
import '../style/componentstyle/resume.css'

const Resume = () => (
    <section id="resume">
        <h1>RESUME</h1>
        <div className="header-bar"></div>
        <div className="resume-container">
            <iframe className="iframe" src="Profile.pdf" title="resume">Presss me: <a href="../assets/Profile.pdf">Download PDF</a></iframe>
        </div>
    </section>
)

export default Resume