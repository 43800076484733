import React from 'react'
import { FaChartBar, FaLaptop, FaLaptopCode, FaLinkedin, FaGithub, FaInstagramSquare } from "react-icons/fa";
import '../style/componentstyle/about.css'
import meImg from '../img/me.png'

const About = () => {

    return (
        <section id="about">
            <h1>ABOUT</h1>
            <div className="header-bar"></div>
            <div className="skilles">
                <div className="logos">
                        <div className="skill">
                            <div className="hex-contenedor">
                                    <i className="logo">
                                        <FaLaptop/>
                                    </i>
                            </div>
                            <div className="logo-desc">
                                <h2>Front End</h2>
                            </div>
                        </div>

                        <div className="skill">
                            <div className="hex-contenedor">
                                <div className="hexagon">
                                    <i className="logo">
                                        <FaLaptopCode/>
                                    </i>
                                </div>
                            </div>
                            <div className="logo-desc">
                                <h2>Back End</h2>
                            </div>
                        </div>
                        <div className="skill">
                            <div className="hex-contenedor">
                                <div className="hexagon">
                                    <i className="logo">
                                        <FaChartBar/>
                                    </i>
                                </div>
                            </div>
                            <div className="logo-desc">
                                <h2>Data Analitycs</h2>
                            </div>
                        </div>
                </div>
            </div>

            <div className="about-info">
                <div className="img-info__container">
                    <img src={meImg} alt="avilio amador"/>
                    <div className="info">
                        <h2>Who's Avilio?</h2>
                        <p>
                            I'm a Software Engineer for
                            <a className="highlight" href="https://www.bch.hn/" target="_blank" rel="noreferrer"> Banco Central de Honduras </a> in Tegucigalpa, 
                            Honduras. With solid <span>Front-end</span> knowledge of <span>HTML</span>, <span>CSS</span>, <span>Javascript</span>, <span>React</span>, Angular and Typescript. Strong understanding of design principles 
                            and responsive design. With <span>Back-end</span> expertise <span>VB/C#/.NET</span>, <span>Python/Django</span> and <span>RESTful APIs</span>. Relational database administration using 
                            <span> Oracle</span> and <span>Sql Server</span>.                        
                        </p>
                        <p>At the same time, I'm walking through the path of become a Data Analyst.</p>
                        <p>Positive attitude, strong work ethic, out-of-the-box thinking and high level of professional integrity.  </p>
                        <div className="links">
                            <div className="linkedin" ><a href="https://www.linkedin.com/in/avilioamador/"  target="_blank"><FaLinkedin/></a></div>
                            <div className="github"><a href="https://github.com/aviliodev" target="_blank"><FaGithub/></a></div>
                            <div className="instagram"><a href="https://www.instagram.com/avi_lio/" target="_blank"><FaInstagramSquare/></a></div>
                        </div>

                    </div>                    
                </div>


            </div>

    </section>   
    )
}

export default About