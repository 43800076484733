import React from 'react'


const Button = ({className, ptype, onPress}) => {

    return(
        <button
            className={className}
            type={ptype.type}
            id={ptype.id} 
            value={ptype.type}
            onClick={onPress}
        > 
            {ptype.description}
        </button>
    );
};

export default Button;